.bg {
  background: #e6f3fe;
  height: 100vh;
  width: 100%;
}

.body {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .logo {
    height: 48px;
    margin-bottom: 40px;
  }

  :global(.login-basic-card) {
    height: 340px;
  }
}

.left-bottom {
  position: fixed;
  bottom: 0;
}

.right-top {
  position: fixed;
  transform: rotate(180deg);
  right: 0;
  top: 0;
}

.footer {
  position: absolute;
  bottom: 32px;
  text-align: center;
  width: 100%;
  color: #888888;
  font-size: 14px;

  :global(.ant-btn-link) {
    padding: 0;
    height: auto;
  }
}

