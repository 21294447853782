.panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  :global(.ant-divider) {
    margin: 0 0 16px 0;
    border-top-color: #dbdbdb;

    :global(.ant-divider-inner-text) {
      padding: 0 8px;
      font-size: 12px;
      line-height: 16px;
      color: #909399;
    }
  }
}

