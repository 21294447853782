.basic-card {
  width: 360px;
  height: 420px;
  background-color: white;
  border-radius: 8px;
  padding: 32px;
  position: relative;

  :global(.ant-input) {
    background: #f8f8f8;
    border-radius: 4px;
    font-size: 14px;
    line-height: 22px;
    padding: 12px 16px;
    border: none;
    margin-bottom: 8px;

    &:nth-last-of-type(1) {
      margin-bottom: 0;
    }
  }
  :global(.ant-input-affix-wrapper) {
    background: #f8f8f8;
    padding: 12px 16px;
    border: none;
  }

  :global(.ant-btn-link) {
    padding: 0;
    height: auto;
  }

  :global(.anticon) {
    cursor: pointer;
  }

  :global(.antd-country-phone-input) {
    margin-bottom: 8px;
    font-size: 12px;
    :global(.ant-select) {
      min-width: 60px;
      line-height: 22px !important;
      height: 22px !important;
    }
    :global(.ant-select-selection-item) {
      line-height: inherit !important;
    }
    :global(.ant-select-arrow) {
      color: #606266 !important;
    }
    :global(.ant-select-selector) {
      height: inherit !important;
      line-height: inherit !important;
      padding-left: 0 !important;
      &::after {
        line-height: inherit !important;
      }
    }
    :global(.ant-select-selection-search) {
      left: 0 !important;
      input {
        height: inherit !important;
      }
    }
  }
}

.back-button {
  position: absolute;
  top: 37px;
  left: 32px;
}

.header {
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 24px;
}

.primary-button {
  width: 100%;
  height: 46px !important;
  letter-spacing: 0.1em;
  font-weight: 600;
  font-size: 14px;
}

.buttons {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
}

.link-button,
.help-button {
  font-size: 14px;
  line-height: 22px;
  margin-top: 16px;
}

.help-button {
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  user-select: none;
}

.tip,
.error {
  font-size: 12px;
  line-height: 22px;
  height: 22px;
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tip {
  color: rgba(0, 0, 0, 0.35);
}
.error {
  color: #f5222d;
}

