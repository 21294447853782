.app {
  background: #e6f3fe;
  width: 100vw;
  height: 100vh;
}

.logo {
  width: 160px;
  position: fixed;
  left: 32px;
  top: 32px;
}

.welcome {
  height: 420px;
  margin-right: 40px;
}

.content-wrapper {
  z-index: 0;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  z-index: 1;
  position: absolute;
  bottom: 16px;
  text-align: center;
  width: 100%;
  color: #888888;
  font-size: 12px;

  a,
  a:hover,
  a:focus,
  a:active {
    color: #888888;
  }
  p {
    margin-bottom: 0px;
  }
}

